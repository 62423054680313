import React, { useState } from "react";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import { updatePatientLanguage } from "../redux/actions/patientActions";
import axios from 'axios';
import CountryPhone from "./components/CountryPhone";
import { useTranslation } from 'react-i18next';

const PatientSignupRequestOtp = ({ updatePatientLanguage, language }) => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [number, setNumber] = useState("");
    const [countryArr, setCountryArr] = useState([]);

    const handleSubmit = e => {
        e.preventDefault();
        const country = {
            code: countryArr.countryCode,
            country: countryArr.name
        }
        setError("")
        setLoading(true);
        axios.post('/api/hcp/checkPatient', { number, country })
            .then(res => {
                localStorage.setItem("patient-number", number);
                axios.post('/api/patient/sendOtp', { number })
                    .then(res => {
                        setLoading(false);
                        navigate("/patient/patientEnterOtpSignup");
                    })
                    .catch(err => {
                        setError(err.response.data.error);
                        setLoading(false);
                    });
            })
            .catch(err => {
                setError(err.response.data.error);
                setLoading(false);
            });
    }

    return (
        <div className="patient-login-page">
            <div style={{ height: "5vh" }}></div>
            <div className="patient-login-container">
                <div className="d-flex justify-content-end">
                    <div className="hcp-signup-lang">
                        <p className={language === "en" ? "hcp-signup-lang-border px-2" : "px-2"} onClick={() => updatePatientLanguage("en")}>ENG</p>
                        <p className={language === "bm" ? "hcp-signup-lang-border px-2" : "px-2"} onClick={() => updatePatientLanguage("bm")}>BM</p>
                        <p className={language === "ch" ? "hcp-signup-lang-border px-2" : "px-2"} onClick={() => updatePatientLanguage("ch")}>MAN</p>
                    </div>
                </div>

                <div className="form-container">
                    <div>
                        <h2 className="hcp-signup-title">{t("Sign up")}</h2>
                        <p className="sign-up-subtitle"><i>* {t("Mandatory field to be filled")}</i></p>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div>
                            <label className="hcp-signup-phone-label"><b>{t("PHONE NUMBER")} *</b></label>
                            <CountryPhone value={number} onChange={(value, country) =>  {setNumber(value);setCountryArr(country)}}/>
                        </div>

                        {
                            error ? (
                                <div className="pt-3">
                                    <p style={{ color: "red", textAlign: "center" }}>{t(error)}</p>
                                </div>
                            ) : null
                        }

                        <div className="pt-5">
                            {
                                loading ? (
                                    <div className="text-center">
                                        <CircularProgress />
                                    </div>
                                ) : (
                                    <div className="button-container">
                                        <button type="submit" className="btn form-control patient-signup-button py-3">
                                            <b>{t("SIGN UP")}</b>
                                        </button>
                                    </div>
                                )
                            }
                        </div>

                    </form>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        language: state.patient.language
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updatePatientLanguage: data => dispatch(updatePatientLanguage(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientSignupRequestOtp);
