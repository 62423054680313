import lipitor10mg from "./assets/image/product/lipitor10mg.png";
import lipitor20mg from "./assets/image/product/lipitor20mg.png";
import lipitor40mg from "./assets/image/product/lipitor40mg.png";
import lipitor80mg from "./assets/image/product/lipitor80mg.png";
import norvasc5mg from "./assets/image/product/norvasc5mg.png";
import norvasc10mg from "./assets/image/product/norvasc10mg.png";
import caduet510mg from "./assets/image/product/caduet510mg.png";
import caduet520mg from "./assets/image/product/caduet520mg.png";
import caduet540mg from "./assets/image/product/caduet540mg.png";
import caduet1010mg from "./assets/image/product/caduet1010mg.png";
import caduet1020mg from "./assets/image/product/caduet1020mg.png";
import caduet1040mg from "./assets/image/product/caduet1040mg.png";
import adaxilSachets from "./assets/image/product/adaxilSachet.png";
import legalon140mg from "./assets/image/product/legalon140mg.png";
import viagra100mg from "./assets/image/product/viagra100mg.png";
import viartrilSCapsule from "./assets/image/product/viartrilSCapsule.png";
import viartrilSSachet from "./assets/image/product/viartrilSSachet.png";
import lyrica50mg from "./assets/image/product/lyrica50mg.jpg";
import lyrica75mg from "./assets/image/product/lyrica75mg.jpg";
import celebrex200mg from "./assets/image/product/celebrex200mg.jpg";

export const medicationImage = (medicationName, medicationSku) => {
    if (medicationName === "Lipitor" && medicationSku === "10 mg") {
        return lipitor10mg;
    } else if (medicationName === "Lipitor" && medicationSku === "20 mg") {
        return lipitor20mg;
    } else if (medicationName === "Lipitor" && medicationSku === "40 mg") {
        return lipitor40mg;
    } else if (medicationName === "Lipitor" && medicationSku === "80 mg") {
        return lipitor80mg;
    } else if (medicationName === "Norvasc" && medicationSku === "5 mg") {
        return norvasc5mg;
    } else if (medicationName === "Norvasc" && medicationSku === "10 mg") {
        return norvasc10mg;
    } else if (medicationName === "Caduet" && medicationSku === "5/10mg") {
        return caduet510mg;
    } else if (medicationName === "Caduet" && medicationSku === "5/20mg") {
        return caduet520mg;
    } else if (medicationName === "Caduet" && medicationSku === "5/40mg") {
        return caduet540mg;
    } else if (medicationName === "Caduet" && medicationSku === "10/10mg") {
        return caduet1010mg;
    } else if (medicationName === "Caduet" && medicationSku === "10/20mg") {
        return caduet1020mg;
    } else if (medicationName === "Caduet" && medicationSku === "10/40mg") {
        return caduet1040mg;
    } else if (medicationName === "Viagra" && medicationSku === "100 mg") {
        return viagra100mg;
    } else if (medicationName === "Viagra" && medicationSku === "50 mg") {
        return viagra100mg;
    } else if (medicationName === "Legalon" && medicationSku === "140 mg") {
        return legalon140mg;
    } else if (medicationName === "Viartril-S Sachets" && medicationSku === "1500 mg") {
        return viartrilSSachet;
    } else if (medicationName === "Viartril-S Capsules" && medicationSku === "500 mg") {
        return viartrilSCapsule;
    } else if (medicationName === "Adaxil Sachets" && medicationSku === "750mg/600mg") {
        return adaxilSachets;
    } else if (medicationName === "Celebrex Capsules" && medicationSku === "200 mg") {
        return celebrex200mg;
    } else if (medicationName === "Lyrica Capsules" && medicationSku === "50 mg") {
        return lyrica50mg;
    } else if (medicationName === "Lyrica Capsules" && medicationSku === "75 mg") {
        return lyrica75mg;
    }
    //  else if (medicationName === "Fulphila" && medicationSku === "6mg/0.6ml") {
    //     return norvasc5mg;
    // }
};

// for admin portal sku filter
export const medicationSkuList = [
    "LIPITOR 10MG",
    "LIPITOR 20MG",
    "LIPITOR 40MG",
    "LIPITOR 80MG",
    "NORVASC 5MG",
    "NORVASC 10MG",
    "CADUET 5/10MG",
    "CADUET 5/20MG",
    "CADUET 5/40MG",
    "CADUET 10/10MG",
    "CADUET 10/20MG",
    "CADUET 10/40MG",
    "VIAGRA 100MG",
    // "VIAGRA 50MG",
    "LEGALON 140MG",
    "VIARTRIL-S 1500MG SACHETS",
    "VIARTRIL-S 500MG CAPSULES",
    "ADAXIL SACHETS",
    "CELEBREX CAPSULES 200MG",
    "LYRICA CAPSULES 50MG",
    "LYRICA CAPSULES 75MG"
]

// for admin portal brand filter
export const medicationBrandList = [
    {
        brandName: "Lipitor",
        value: "Lipitor"
    },
    {
        brandName: "Norvasc",
        value: "Norvasc"
    },
    {
        brandName: "Caduet",
        value: "Caduet"
    },
    {
        brandName: "Viagra",
        value: "Viagra"
    },
    {
        brandName: "Legalon",
        value: "Legalon"
    },
    {
        brandName: "Viartril-S Sachets",
        value: "Viartril-S Sachets"
    },
    {
        brandName: "Viartril-S Capsules",
        value: "Viartril-S Capsules"
    },
    {
        brandName: "Adaxil",
        value: "Adaxil Sachets"
    },
    {
        brandName: "Celebrex Capsules",
        value: "Celebrex Capsules"
    },
    {
        brandName: "Lyrica Capsules",
        value: "Lyrica Capsules"
    },
]