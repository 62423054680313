import { NavLink } from "react-router-dom";
import { connect } from 'react-redux';
import logo from "../../assets/image/hcpWelcomeLogo.png";

const Sidebar = ({ role }) => {
    return (
        <div className="admin-sidebar">
            <div className="text-center">
                <img src={logo} alt="logo" className="img-fluid" />
            </div>
            <div className="sidebar-menu-text">
                <ul>
                    <li className="sidebar-menu-bottom-line">
                        <NavLink activeclassname="active" className="admin-nav-link" to="/admin/dashboard">
                            <span>Performance Overview</span>
                        </NavLink>
                    </li>
                    <li className="sidebar-menu-bottom-line">Reports</li>
                    <ul>
                        <li className="sidebar-menu-bottom-line">
                            <NavLink activeclassname="active" className="admin-nav-link" to="/admin/patientRegistrationReport">
                                <span>Patient Registration</span>
                            </NavLink>
                        </li>
                        <li className="sidebar-menu-bottom-line">
                            <NavLink activeclassname="active" className="admin-nav-link" to="/admin/patientSubmissionReport">
                                <span>Patient Submission</span>
                            </NavLink>
                        </li>
                        <li className="sidebar-menu-bottom-line">
                            <NavLink activeclassname="active" className="admin-nav-link" to="/admin/patientRedemptionReport">
                                <span>Patient Redemption</span>
                            </NavLink>
                        </li>
                        <li className={role === "dev" || role === "admin" ? "sidebar-menu-bottom-line" : "sidebar-menu-bottom-noline"}>
                            <NavLink activeclassname="active" className="admin-nav-link" to="/admin/pendingRedemptionReport">
                                <span>Pending Redemption</span>
                            </NavLink>
                        </li>
                        {
                            role === "dev" || role === "admin" ? (
                                <li className="sidebar-menu-bottom-line">
                                    <NavLink activeclassname="active" className="admin-nav-link" to="/admin/redemptionReport">
                                        <span>Redemption Report</span>
                                    </NavLink>
                                </li>
                            ) : null
                        }
                        {
                            role === "dev" || role === "admin" ? (
                                <li className="sidebar-menu-bottom-noline">
                                    <NavLink activeclassname="active" className="admin-nav-link" to="/admin/validation">
                                        <span>Validation</span>
                                    </NavLink>
                                </li>
                            ) : null
                        }
                    </ul>
                    <li className="sidebar-menu-bottom-topline">
                        <NavLink activeclassname="active" className="admin-nav-link" to="/admin/salesData">
                            <span>Sales Data</span>
                        </NavLink>
                    </li>
                    {
                        role === "dev" ? (
                            <li className="sidebar-menu-bottom-topline">
                                <NavLink activeclassname="active" className="admin-nav-link" to="/admin/inventoryUpdate">
                                    <span>Inventory Update</span>
                                </NavLink>
                            </li>
                        ) : null
                    }
                    {
                        role === "dev" ? (
                            <li className="sidebar-menu-bottom-topline">
                                <NavLink activeclassname="active" className="admin-nav-link" to="/admin/hcpEnrollmentRecords">
                                    <span>HCP Enrollment Records</span>
                                </NavLink>
                            </li>
                        ) : null
                    }
                    {
                        role === "dev" ? (
                            <li className="sidebar-menu-bottom-topline">
                                <NavLink activeclassname="active" className="admin-nav-link" to="/admin/hcpPerformances">
                                    <span>HCP Performances</span>
                                </NavLink>
                            </li>
                        ) : null
                    }
                </ul>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        role: state.admin.role
    };
};

export default connect(mapStateToProps, null)(Sidebar);