import React, { useState, /*useEffect*/ } from "react";
import { /*FormControlLabel,*/ CircularProgress } from "@mui/material";
//import Checkbox from "@material-ui/core/Checkbox";
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import { updatePatientLanguage, /*updatePatientRememberMe*/ } from "../redux/actions/patientActions";
import axios from 'axios';
import CountryPhone from "./components/CountryPhone";
import { useTranslation } from 'react-i18next';

const PatientLogin = ({ language, updatePatientLanguage, /*rememberme, updatePatientRememberMe*/ }) => {

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [number, setNumber] = useState("");

  // useEffect(() => {
  //   if (rememberme) {
  //     setNumber(localStorage.getItem("patient-number") || "")
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const handleSubmit = e => {
    e.preventDefault();

    setError("")
    setLoading(true);

    axios.post('/api/patient/sendOtp', { number })
      .then(res => {
        localStorage.setItem("patient-number", number);
        setLoading(false);
        navigate('/patient/patientEnterOtp');
      })
      .catch(err => {
        setError(err.response.data.error);
        setLoading(false);
      });
  }

  return (
    <div className="patient-login-page">
      <div style={{ height: "5vh" }}></div>
      <div className="patient-login-container">
        <div className="d-flex justify-content-end">
          <div className="hcp-signup-lang">
            <p className={language === "en" ? "hcp-signup-lang-border px-2" : "px-2"} onClick={() => updatePatientLanguage("en")}>ENG</p>
            <p className={language === "bm" ? "hcp-signup-lang-border px-2" : "px-2"} onClick={() => updatePatientLanguage("bm")}>BM</p>
            <p className={language === "ch" ? "hcp-signup-lang-border px-2" : "px-2"} onClick={() => updatePatientLanguage("ch")}>MAN</p>
          </div>
        </div>

        <div>
          <h2 className="hcp-signup-title">{t("Login")}</h2>
        </div>

        <div className="form-container">
          <form onSubmit={handleSubmit}>
            <div className="pt-5">
              <label className="hcp-signup-phone-label"><b>{t("PHONE NUMBER")}</b></label>
              <CountryPhone value={number} onChange={(number) => setNumber(number)}/>
              {/* <FormControlLabel
              label="Remember me"
              control={
                <Checkbox
                  color="default"
                  checked={rememberme}
                  onChange={e => updatePatientRememberMe(e.target.checked)}
                />
              }
            /> */}
            </div>

            {
              error ? (
                <div className="pt-3">
                  <p style={{ color: "red", textAlign: "center" }}>{t(error)}</p>
                </div>
              ) : null
            }

            <div className="mt-3">
              {
                loading ? (
                  <div className="text-center">
                    <CircularProgress />
                  </div>
                ) : (
                  <div className="button-container">
                    <button type="submit" className="btn form-control patient-signup-button py-3">
                      <b>{t("REQUEST OTP")}</b>
                    </button>
                  </div>
                )
              }
            </div>
          </form>

          <div className="patient-didnt-received">
            <div className="patient-line"></div>
            <p className="patient-didnt-receive-text">
              {t("Don't have an account?")}
            </p>
            <div className="patient-line"></div>
          </div>
          <div className="button-container">
            <button className="btn form-control patient-login-button py-3" onClick={() => navigate("/patient/patientSignUpRequestOtp")}>
              <b>{t("Sign up")}</b>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    language: state.patient.language,
    //rememberme: state.patient.rememberme
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updatePatientLanguage: data => dispatch(updatePatientLanguage(data)),
    //updatePatientRememberMe: data => dispatch(updatePatientRememberMe(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientLogin);
